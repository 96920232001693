var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-2 mx-auto", staticStyle: { "max-width": "700px" } },
    [
      _c(
        "v-alert",
        {
          staticClass: "mb-2",
          attrs: { type: "warning", outlined: "", dense: "" }
        },
        [
          _vm._v(
            "\n    These instructions must be performed on the iPhone/iPad that you're setting up to be monitored.  To email these instructions to the iOS device, click\n    "
          ),
          _c("a", { attrs: { href: _vm.mailToLink, target: "_blank" } }, [
            _vm._v("here")
          ]),
          _vm._v(".\n  ")
        ]
      ),
      _c(
        "v-expansion-panels",
        { attrs: { popout: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("span", { staticClass: "heading" }, [
                  _vm._v("View iOS 14+ Instructions")
                ])
              ]),
              _c("v-expansion-panel-content", [
                _c("ol", [
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _c("div", [
                        _vm._v("Download the mobileconfig file below, "),
                        _c(
                          "span",
                          { staticClass: "text-decoration-underline" },
                          [_vm._v("using Safari")]
                        ),
                        _vm._v(" on the iPhone/iPad you wish to monitor.")
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", outlined: "" },
                          on: { click: _vm.downloadMobileConfig }
                        },
                        [_vm._v("Download")]
                      ),
                      _c("div", { staticClass: "caption mt-2" }, [
                        _vm._v(
                          "\n              If you wish to prevent the ability to disable the mobileconfig profile, you'll need to install it on a supervised iOS device using Apple Configurator 2.  Unfortunately these steps are a bit technical.  You can view supervised setup guide "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://" +
                                _vm.supportDomain +
                                "/articles/ios/supervised-setup-guide",
                              target: "_blank"
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(
                          ".  You can download the supervised mobileconfig profile by clicking "
                        ),
                        _c(
                          "a",
                          { on: { click: _vm.downloadSupervisedMobileConfig } },
                          [_vm._v("here")]
                        ),
                        _vm._v(".\n            ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Allow`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/mobileconfig.0.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Close`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/mobileconfig.01.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v("\n            Tap the `Home` button\n          ")
                  ]),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v("\n            Open the `Settings` app\n          ")
                  ]),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click `Profile Downloaded`\n            "
                      ),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/profile.downloaded.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Install`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/install.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            If prompted, enter your phone's unlock passcode.\n            "
                      ),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/unlock.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Install`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/install.2.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Install`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/install.3.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click `Done`\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/mobileconfig/done.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v(
                      "\n            This device is now setup, and you should start seeing internet traffic recorded.\n          "
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v(
                  "\n        View iOS 10-13 instructions (NOT Recommended)\n      "
                )
              ]),
              _c("v-expansion-panel-content", [
                _c("ol", [
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v("Download the "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://apps.apple.com/us/app/dnscloak-secure-dns-client/id1452162351",
                          target: "_blank"
                        }
                      },
                      [_vm._v("DNSCloak app")]
                    ),
                    _vm._v(" from the App store."),
                    _c(
                      "div",
                      { staticClass: "text-right caption font-italic" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://blog.truple.io/2020/04/22/apple-developer-account.html",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Why don't we offer an app?")]
                        )
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v("Open up the DNSCloak app.")
                  ]),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click the edit configuration icon in the top right hand corner.\n            "
                      ),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/edit.config.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Select all of the existing text.\n            "
                      ),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/select.all.text.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Tap "),
                      _c("b", [_vm._v("cut")]),
                      _vm._v(" to delete it all.\n            "),
                      _c("v-img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/cut.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "pb-2" }, [
                            _vm._v(
                              "Paste the DNSCloak Config into the DNSCloak app."
                            ),
                            _c("br"),
                            _c("span", { staticClass: "caption" }, [
                              _vm._v(
                                'Do this by clicking the "Copy Config" button below, then switch to the DNSCloak app and tap and hold until the "paste" option appears.  The click the paste button.'
                              )
                            ])
                          ]),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.sdnsStamp,
                                  expression: "sdnsStamp",
                                  arg: "copy"
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.copyToClipboard,
                                  expression: "copyToClipboard",
                                  arg: "success"
                                }
                              ],
                              attrs: { outlined: "", color: "primary" }
                            },
                            [
                              _c("v-icon", { staticClass: "pr-1" }, [
                                _vm._v("file_copy")
                              ]),
                              _vm._v(
                                "\n                Copy Config\n              "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-img", {
                        staticClass: "mt-2",
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/paste.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click the checkmark to save the changes\n            "
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/check.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click the back arrow\n            "
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/back.config.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click the 3 horizontal "),
                      _c("b", [_vm._v("bar")]),
                      _vm._v(" menu in the top "),
                      _c("b", [_vm._v("left")]),
                      _vm._v(" corner.\n            "),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/horizontal.menu.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Under general options, ensure the following are "
                      ),
                      _c("b", [_vm._v("enabled")]),
                      _vm._v("."),
                      _c("br"),
                      _c("ul", [
                        _c("li", [_vm._v("Connect On Demand")]),
                        _c("li", [_vm._v("Cache responses")])
                      ]),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/connect.on.demand.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click the back arrow\n            "
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/back.settings.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            'Click on "Truple (' +
                              (this.deviceName
                                ? this.deviceName
                                : this.deviceNameProp) +
                              ')"'
                          )
                        )
                      ]),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/click.server.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        '\n            Click "Use this Server" if it\'s not already marked to use it.\n            '
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/use.server.png"
                        }
                      }),
                      _vm._v(
                        '\n\n            If you\'re prompted to add a VPN profile, click "Allow" and provide any credentials your phone asks of you in order to add the VPN profile.\n            '
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/vpn.profile.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v(
                        "\n            Click the Play button to start the filter (if it's not started already).\n            "
                      ),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/play.png"
                        }
                      }),
                      _vm._v(
                        "\n\n            If it cycles through starting and stopping, let it cycle a few times and see if it'll stay started.  If it continually cycles, stop it then start it again."
                      ),
                      _c("br"),
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _vm._v(
                                "\n                Once it's started, click the button below from your iPhone/iPad that you're setting up internet accountability on to test whether it's working properly."
                              ),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "caption font-italic" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.mailToLink,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("(Click here to email this page)")]
                                  )
                                ]
                              ),
                              _c("br"),
                              _c("br"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    loading: _vm.testSuccess === "running",
                                    disabled: _vm.testSuccess === "running"
                                  },
                                  on: { click: _vm.runTest }
                                },
                                [_vm._v("Run test")]
                              ),
                              _vm.testSuccess === "running"
                                ? _c("div", [
                                    _vm._v(
                                      "\n                  Test is running...\n                "
                                    )
                                  ])
                                : _vm.testSuccess
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-alert",
                                        { attrs: { type: "success" } },
                                        [
                                          _vm._v(
                                            "\n                    Congrats!  Internet accountability is working!\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm.testSuccess === false
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "Test failed.  You need to click the button from the device you installed and set DNSCloak up on.  DNSCloak must be configured (see steps above) and it must be running the VPN in order for this test to pass.  Check those steps then re-run the test."
                                          )
                                        ]
                                      ),
                                      _c("br"),
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          "If you are having issues getting the test to pass, contact "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:support@truple.io?subject=Issues%20with%20DNSCloak%20Test"
                                            }
                                          },
                                          [_vm._v("support@truple.io")]
                                        ),
                                        _vm._v(".")
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s("Error: " + _vm.testOutput) +
                                            "\n                  "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _vm._v("\n            Click the 3 horizontal "),
                      _c("b", [_vm._v("dot")]),
                      _vm._v(" menu in the top "),
                      _c("b", [_vm._v("right")]),
                      _vm._v(" corner.\n            "),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/dot.menu.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "pt-5" },
                    [
                      _c("div", [_vm._v("Click `Set passcode`")]),
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("We "),
                        _c("b", [_vm._v("strongly")]),
                        _vm._v(
                          " recommend you use this app for a few days before locking yourself out.  Once you lock yourself out, make sure you have access to the passcode through a trusted friend or loved one.  Truple will "
                        ),
                        _c("b", [_vm._v("not")]),
                        _vm._v(
                          " be able to provide an unlock code for this app."
                        )
                      ]),
                      _c("v-img", {
                        attrs: {
                          eager: "",
                          src: "/static/img/dnscloak/set.passcode.png"
                        }
                      })
                    ],
                    1
                  ),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v(
                      "Enter in a passcode, then enter it again to confirm it"
                    )
                  ]),
                  _c("li", { staticClass: "pt-5" }, [
                    _vm._v("(Optional) Setup "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://support.apple.com/en-us/HT201304#set-restrictions",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Parental Controls on the iPhone")]
                    ),
                    _vm._v(
                      " to prevent uninstalling the app.  If you don't do this you can still identify when the app was uninstalled when no websites were captured and you know the device was being used."
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: true },
          model: {
            value: _vm.showMessage,
            callback: function($$v) {
              _vm.showMessage = $$v
            },
            expression: "showMessage"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
          _c(
            "v-btn",
            {
              staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
              attrs: { icon: "", small: "", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.showMessage = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }