<template>
  <div style="max-width: 700px;" class="pa-2 mx-auto">
    <v-alert type="warning" outlined dense class="mb-2">
      These instructions must be performed on the iPhone/iPad that you're setting up to be monitored.  To email these instructions to the iOS device, click
      <a :href="mailToLink" target="_blank">here</a>.
    </v-alert>

    <v-expansion-panels popout>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="heading">View iOS 14+ Instructions</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li class="pt-5">
              <div>Download the mobileconfig file below, <span class="text-decoration-underline">using Safari</span> on the iPhone/iPad you wish to monitor.</div>
              <v-btn color="primary" @click="downloadMobileConfig" outlined>Download</v-btn>
              <div class="caption mt-2">
                If you wish to prevent the ability to disable the mobileconfig profile, you'll need to install it on a supervised iOS device using Apple Configurator 2.  Unfortunately these steps are a bit technical.  You can view supervised setup guide <a :href="`https://${supportDomain}/articles/ios/supervised-setup-guide`" target="_blank">here</a>.  You can download the supervised mobileconfig profile by clicking <a @click="downloadSupervisedMobileConfig">here</a>.
              </div>
            </li>
            <li class="pt-5">
              Click `Allow`
              <v-img eager src="/static/img/mobileconfig/mobileconfig.0.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Click `Close`
              <v-img eager src="/static/img/mobileconfig/mobileconfig.01.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Tap the `Home` button
            </li>
            <li class="pt-5">
              Open the `Settings` app
            </li>
            <li class="pt-5">
              Click `Profile Downloaded`
              <v-img eager src="/static/img/mobileconfig/profile.downloaded.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Click `Install`
              <v-img eager src="/static/img/mobileconfig/install.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              If prompted, enter your phone's unlock passcode.
              <v-img eager src="/static/img/mobileconfig/unlock.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Click `Install`
              <v-img eager src="/static/img/mobileconfig/install.2.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Click `Install`
              <v-img eager src="/static/img/mobileconfig/install.3.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Click `Done`
              <v-img eager src="/static/img/mobileconfig/done.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              This device is now setup, and you should start seeing internet traffic recorded.
            </li>
          </ol>

        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          View iOS 10-13 instructions (NOT Recommended)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li class="pt-5">Download the <a href="https://apps.apple.com/us/app/dnscloak-secure-dns-client/id1452162351" target="_blank">DNSCloak app</a> from the App store.<div class="text-right caption font-italic"><a href="https://blog.truple.io/2020/04/22/apple-developer-account.html" target="_blank">Why don't we offer an app?</a></div></li>
            <li class="pt-5">Open up the DNSCloak app.</li>
            <li class="pt-5">
              Click the edit configuration icon in the top right hand corner.
              <v-img eager src="/static/img/dnscloak/edit.config.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Select all of the existing text.
              <v-img eager src="/static/img/dnscloak/select.all.text.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              Tap <b>cut</b> to delete it all.
              <v-img eager src="/static/img/dnscloak/cut.png" style="width: 100%;" />
            </li>
            <li class="pt-5">
              <div>
                <div class="pb-2">Paste the DNSCloak Config into the DNSCloak app.<br /><span class="caption">Do this by clicking the "Copy Config" button below, then switch to the DNSCloak app and tap and hold until the "paste" option appears.  The click the paste button.</span></div>
                <!--            <v-textarea-->
                <!--              v-model="sdnsStamp"-->
                <!--              @click="copyToClipboard"-->
                <!--              class="pb-2"-->
                <!--              label="DNSCloak Config (click to copy)"-->
                <!--              dense-->
                <!--              :auto-grow="false"-->
                <!--              filled-->
                <!--              outlined-->
                <!--              no-resize-->
                <!--              readonly-->
                <!--              hide-details-->
                <!--            >-->
                <!--            </v-textarea>-->
                <v-btn outlined
                       color="primary"
                       v-clipboard:copy="sdnsStamp"
                       v-clipboard:success="copyToClipboard">
                  <v-icon class="pr-1">file_copy</v-icon>
                  Copy Config
                </v-btn>
              </div>
              <!--              <v-img eager src="/static/img/dnscloak/copy.config.png" />-->
              <v-img eager class="mt-2" src="/static/img/dnscloak/paste.png" />
            </li>
            <li class="pt-5">
              Click the checkmark to save the changes
              <v-img eager src="/static/img/dnscloak/check.png" />
            </li>
            <li class="pt-5">
              Click the back arrow
              <v-img eager src="/static/img/dnscloak/back.config.png" />
            </li>
            <li class="pt-5">
              Click the 3 horizontal <b>bar</b> menu in the top <b>left</b> corner.
              <v-img eager src="/static/img/dnscloak/horizontal.menu.png" />
            </li>
            <li class="pt-5">
              Under general options, ensure the following are <b>enabled</b>.<br/>
              <ul>
                <li>Connect On Demand</li>
                <li>Cache responses</li>
              </ul>

              <v-img eager src="/static/img/dnscloak/connect.on.demand.png" />
            </li>
            <li class="pt-5">
              Click the back arrow
              <v-img eager src="/static/img/dnscloak/back.settings.png" />
            </li>
            <!--            <li>(Optional) Under Advanced options, enable <b>Disconnect on sleep</b> if you wish to reduce the battery consumption&#45;&#45;however this can cause the phone to not have an internet conne</li>-->

            <!--            <li>Click the 3 hoizontal bar menu in the top left corner</li>-->
            <!--            <li>Click Forwarding Rules</li>-->
            <!--            <li>Download the <router-link to="forwardingrules">forwarding rules</router-link> on the iPhone/iPad.</li>-->
            <li class="pt-5">
              <div>{{`Click on "Truple (${this.deviceName ? this.deviceName : this.deviceNameProp})"`}}</div>
              <v-img eager src="/static/img/dnscloak/click.server.png" />
            </li>
            <li class="pt-5">
              Click "Use this Server" if it's not already marked to use it.
              <v-img eager src="/static/img/dnscloak/use.server.png" />

              If you're prompted to add a VPN profile, click "Allow" and provide any credentials your phone asks of you in order to add the VPN profile.
              <v-img eager src="/static/img/dnscloak/vpn.profile.png" />
            </li>
            <li class="pt-5">
              Click the Play button to start the filter (if it's not started already).
              <v-img eager src="/static/img/dnscloak/play.png" />

              If it cycles through starting and stopping, let it cycle a few times and see if it'll stay started.  If it continually cycles, stop it then start it again.<br />

              <v-card outlined>
                <v-card-text>
                  Once it's started, click the button below from your iPhone/iPad that you're setting up internet accountability on to test whether it's working properly.<br />
                  <span class="caption font-italic"><a :href="mailToLink" target="_blank">(Click here to email this page)</a></span><br /><br />
                  <v-btn color="primary" class="mb-2" outlined @click="runTest" :loading="testSuccess === 'running'" :disabled="testSuccess === 'running'">Run test</v-btn>
                  <div v-if="testSuccess === 'running'">
                    Test is running...
                  </div>
                  <div v-else-if="testSuccess">
                    <v-alert type="success">
                      Congrats!  Internet accountability is working!
                    </v-alert>
                  </div>
                  <div v-else-if="testSuccess === false">
                    <v-alert type="error">Test failed.  You need to click the button from the device you installed and set DNSCloak up on.  DNSCloak must be configured (see steps above) and it must be running the VPN in order for this test to pass.  Check those steps then re-run the test.</v-alert>
                    <br />
                    <span class="caption">If you are having issues getting the test to pass, contact <a href="mailto:support@truple.io?subject=Issues%20with%20DNSCloak%20Test">support@truple.io</a>.</span>
                    <div>
                      {{'Error: ' + testOutput}}
                    </div>
                  </div>
                </v-card-text>
              </v-card>


            </li>
            <li class="pt-5">
              Click the 3 horizontal <b>dot</b> menu in the top <b>right</b> corner.
              <v-img eager src="/static/img/dnscloak/dot.menu.png" />
            </li>
            <li class="pt-5">
              <div>Click `Set passcode`</div>
              <div class="caption">We <b>strongly</b> recommend you use this app for a few days before locking yourself out.  Once you lock yourself out, make sure you have access to the passcode through a trusted friend or loved one.  Truple will <b>not</b> be able to provide an unlock code for this app.</div>
              <v-img eager src="/static/img/dnscloak/set.passcode.png" />
            </li>
            <li class="pt-5">Enter in a passcode, then enter it again to confirm it</li>
            <!--        <li class="pt-5">-->
            <!--          Click the back arrow to go back to the main screen-->
            <!--          <v-img eager src="/static/img/dnscloak/pass.settings.back.png" />-->
            <!--        </li>-->
            <li class="pt-5">(Optional) Setup <a href="https://support.apple.com/en-us/HT201304#set-restrictions" target="_blank">Parental Controls on the iPhone</a> to prevent uninstalling the app.  If you don't do this you can still identify when the app was uninstalled when no websites were captured and you know the device was being used.</li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="showMessage"
      :bottom="true">
      {{ message }}
      <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="showMessage = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>
  </div>
</template>

<script>

  import fileDownload from 'js-file-download'
  import SdnsStamp from "../../services/doh/SdnsStamp";
  import Utils from "../../Utils";
  import DNSCloakHelper from "../../services/DNSCloakHelper";
  import AppleMobileConfig from "../../services/doh/AppleMobileConfig";

  export default {
    name: "DNSCloakInstallInstructions",
    components: {

    },
    mounted() {

      if(this.$route.query.filterId) {
        this.filterId = this.$route.query.filterId
      }

      if(this.$route.query.deviceId) {
        this.deviceId = this.$route.query.deviceId
      }

      if(this.$route.query.deviceName) {
        this.deviceName = this.$route.query.deviceName
      }

    },
    data() {
      return {
        supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
        showIOS14Instructions: false,
        filterId: null,
        deviceId: null,
        deviceName: null,
        message: null,
        showMessage: false,
        testSuccess: null,
        testOutput: ''
      }
    },
    props: {
      filterIdProp: {
        type: String,
        required: false
      },
      deviceIdProp: {
        type: String,
        required: false
      },
      deviceNameProp: {
        type: String,
        required: false
      }
    },
    methods: {
      downloadMobileConfig() {
        fileDownload(this.iOS14MobileConfigText, 'truple.mobileconfig', 'application/x-apple-aspen-config')
      },
      downloadSupervisedMobileConfig() {
        fileDownload(this.iOS14MobileConfigSupervisedText, 'supervisedtruple.mobileconfig', 'application/x-apple-aspen-config')
      },
      async runTest() {
        this.testSuccess = 'running'
        await Utils.sleep(1250)
        try {
          let response = await fetch("https://dohtest.truple.io/img/logo_small.png", {
            "headers": {
              "accept": "image/webp,image/apng,image/*,*/*;q=0.8",
              "cache-control": "no-cache",
              "pragma": "no-cache",
              "sec-fetch-dest": "image",
              "sec-fetch-mode": "no-cors",
              "sec-fetch-site": "same-origin"
            },
            "body": null,
            "method": "GET",
          })

          console.log(response)

          this.testOutput = JSON.stringify(response) + " " + response.toString()

          this.testSuccess = response.ok
        } catch(e) {

          console.log(e)
          this.testOutput = JSON.stringify(e) + " " + e.toString()
          //we don't have cors setup properly (though we tried).  This error means DNS resolve worked though, and cors failed.  Means accountability is working.

          let errorString = e.toString()

          this.testSuccess = errorString.endsWith('Preflight response is not successful') || errorString.indexOf('Access-Control-Allow-Origin') !== -1
        }
      },
      copyToClipboard() {
        this.message = 'copied to clipboard'
        this.showMessage = true
      }
    },
    computed: {
      mailToLink() {

        return DNSCloakHelper.getMailLink(
          this.filterIdProp || this.filterId,
          this.deviceIdProp || this.deviceId,
          this.deviceNameProp || this.deviceName
        )
      },
      iOS14MobileConfigText() {
        if(this.filterIdProp) {
          return AppleMobileConfig.build(this.filterIdProp,
            this.deviceIdProp,
            this.deviceNameProp, false)
        } else if(this.filterId) {
          return AppleMobileConfig.build(this.filterId,
            this.deviceId,
            this.deviceName, false)
        } else {
          return null
        }
      },
      iOS14MobileConfigSupervisedText() {
        if(this.filterIdProp) {
          return AppleMobileConfig.build(this.filterIdProp,
              this.deviceIdProp,
              this.deviceNameProp,
      true)
        } else if(this.filterId) {
          return AppleMobileConfig.build(this.filterId,
              this.deviceId,
              this.deviceName, true)
        } else {
          return null
        }
      },
      sdnsStamp() {
        if(this.filterIdProp) {
          return SdnsStamp.calculateConfig(
            this.filterIdProp,
            this.deviceIdProp,
            this.deviceNameProp
          )
        } else if(this.filterId) {
          return SdnsStamp.calculateConfig(
            this.filterId,
            this.deviceId,
            this.deviceName
          )
        } else {
          return null
        }
      }
    }
  }
</script>

<style scoped>
  .faded {

  }
  .focused {

  }
</style>